import * as moment from 'moment'

export default function formatDate(date, format) {
  let frmt = format
  if (!format) {
    frmt = 'DD MMM YYYY' // 'DD MMM YYYY HH:mm'
  }
  const d = moment(date)
  return (d.isValid()) ? d.format(frmt) : ''
}
