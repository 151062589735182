import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
//import MockService from "@/core/mock/mock.service";
//import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import JwtService from "@/core/services/jwt.service";
import Swal from 'sweetalert2';
import VModal from 'vue-js-modal';
import Autocomplete from 'vuejs-auto-complete'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import filters from '@/filter'
import JsonCSV from 'vue-json-csv'
import VueMask from 'v-mask'
import Print from 'vue-print-nb'
import moment from 'moment-timezone'
import VueGoogleAutocomplete from "vue-google-autocomplete";


Vue.use(ElementUI, { locale })
Vue.use(VModal);
Vue.use(filters);
Vue.use(VueMask);
Vue.use(Print);
Vue.use(VueGoogleAutocomplete);
Vue.component('autocomplete', Autocomplete);
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead);
Vue.component('downloadCsv', JsonCSV);

// Vue.filter("capitalize",  function(value)  {
//   if  (!value)  {
//     return  "";
//   }
//   value  =  value.toString();
//   return  value.charAt(0).toUpperCase()  +  value.slice(1);
// });
//Vue.component('elTableExport', elTableExport);
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/styles/style.css";

// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();
const whiteList = ['/login', '/','/forget-password','/mail/invoice/:order_id','/reset-password/:token'];
router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  //Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  if (JwtService.getToken()) {
    if (to.path == "/login") {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    let status = false;
    whiteList.map((url)=>{
      if(url.match(/\/reset-password\/|\/mail\/invoice\//)){
        status = true;
      }
    })

    if (whiteList.indexOf(to.path) !== -1 || status) {
      next();
    } else {
      next("/login");
    }
  }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.directive("loadimage", {
  bind: function(el, binding) {
    el.classList.add("default-logo");
    var image = require('@/assets/images/noimage.png');
    
    if(binding.value != "" && binding.value != null){
      image = process.env.VUE_APP_BASE_IMAGE_URL + binding.value;
    }    
    var imgHtml = document.createElement("img");
    imgHtml.src = image;
    el.appendChild(imgHtml);
  },
  update: function (el, binding) {
    el.classList.add("default-logo");
    el.innerHTML = "";
    var image = require('@/assets/images/noimage.png');
    
    if(binding.value != "" && binding.value != null){
      image = process.env.VUE_APP_BASE_IMAGE_URL + binding.value;
    }    
    var imgHtml = document.createElement("img");
    imgHtml.src = image;
    el.appendChild(imgHtml);
  }
});

/*Vue.directive("loading", {
  bind: function(el, binding, vnode) {
  console.log(binding + vnode)
  var loaderImage = require('@/assets/loader/loader.gif');
    var loaderHtml = document.createElement("div");
    loaderHtml.className = 'loading-container';
    //loaderHtml.style.display="none";    
  loaderHtml.innerHTML = '<span class="vloader"><img src="' + loaderImage + '"/></span>';
    el.appendChild(loaderHtml);
    el.style.position="relative";
  },
  update: function (el, binding) {
    var elem = el.querySelector('.loading-container');
    if(binding.value){
      elem.style.display="block";
    }else if(!binding.value){
      elem.style.display="none";
    }
  }
});*/

Vue.prototype.$isWhiteListPage = function(urlArray) {
  urlArray.map((url)=>{
    if(url.match(/\/reset-password\//)){
      return true;
    }
  })
  return false;
};


Vue.prototype.$showResponse = function(status, message) {
  Swal.fire({
    title: "",
    text: message,
    icon: status,
    confirmButtonClass: "btn btn-secondary"
  });
};

Vue.prototype.$formatDate = function(date, format) {
  var candianTime = moment.tz.setDefault('America/Toronto');
  if (date) {
    return candianTime(String(date)).format(format);
  }
};


Vue.prototype.capitalize = function(input=null)  {
    if  (!input || !input.target.value)  {
      return  "";
    }else{
      var value  =  input.target.value.toString();
      let string = value.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
      input.target.value=string;
    }
    
  };

Vue.prototype.$isColumnShow = function(column, column_selected_list) {
  let status = false;
  if (column) {
    column_selected_list.map((value) =>{
        if(value == column){         
          status = true;
        }
    });
  }
  return status;
};

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
