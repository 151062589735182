const ID_TOKEN_KEY = "id_token";
const PROFILE_KEY = "user_data";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getAttribute = () => {
  return window.localStorage.getItem(PROFILE_KEY);
}

export const setAttribute = userData => {
  window.localStorage.setItem(PROFILE_KEY, userData);
}

export const removeAttribute = () => {
  window.localStorage.removeItem(PROFILE_KEY);
}

export default { getToken, saveToken, destroyToken, getAttribute, setAttribute, removeAttribute };
